<template>
  <div class="config-app">
    <h2 class="app-title">Gestão de Processos</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de processos.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
<!--        <app-label-wrapper label="Ativar Robôs PJe">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('processsos.robos.pje').value" />
          <p class="app-legend m-t-xs m-b-none">Habilita robôs para comunicar com os tribunais e buscar andamentos processuais</p>
        </app-label-wrapper>-->
        <app-label-wrapper label="API para Consulta de Processos">
          <u-select :options="[{label: 'Nenhum', value: ''},{label: 'API-SL', value: 'default'}]" class="app-input" hide-underline v-model="c('processos.apiPje').value" />
          <!--          <p class="app-legend m-t-xs"></p>-->
        </app-label-wrapper>
        <app-label-wrapper label="Credenciais para API">
          <collapse title="Editar credenciais da API" :collapse="false">
            <code-editor v-model="c('processos.apiPjeCredenciais').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>

        <app-label-content>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-content>
      </div>
      <list v-if="active === 'status'" class="app-input-container" />
      <list-classificacao v-if="active === 'classificacao'" class="app-input-container" />
      <list-tipos-parte v-if="active === 'tiposParte'" class="app-input-container" />
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import List from "@/components/cadastro/components/statusprocesso/List"
import ListClassificacao from "@/components/cadastro/components/classificacaoprocesso/List"
import ListTiposParte from "@/components/cadastro/components/tiposparteprocesso/List"
import {USelect} from "uloc-vue"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'

export default {
  name: "ConfigProcessos",
  components: {
    List,
    ListClassificacao,
    ListTiposParte,
    USelect,
    Collapse,
    CodeEditor
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'status',
          label: 'Status de Processos'
        },
        {
          name: 'classificacao',
          label: 'Classificação de Processos'
        },
        {
          name: 'tiposParte',
          label: 'Tipos de Parte'
        }
      ]
    }
  }
}
</script>
